import {useRouter} from 'next/router';

import {AuthService} from '~api/auth.service';
import {replaceRoute, Route} from '~utils/routeUtil';
import {selectCurrentProjectId, selectLandingPage} from '~redux/reducers/userReducer';
import {useAppSelector} from '~redux/index';

function IndexPage(): null {
  const router = useRouter();
  const projectID = useAppSelector(selectCurrentProjectId);
  const userLandingPage = useAppSelector(selectLandingPage);
  if (AuthService.isLoggedIn()) {
    const route = userLandingPage ? `/${userLandingPage.toLowerCase()}` : Route.monitor;
    replaceRoute(route, {query: {projectID, ...router.query}});
  } else if (!window.location.href.includes('503')) {
    replaceRoute(Route.login, {keepModelIdQueryParam: false, keepProjectIdQueryParam: false});
  } else {
    replaceRoute(Route.apiUnavailable);
  }

  // Nothing to render on "/", we always route to either "/login" or "/project"
  return null;
}

export default IndexPage;
